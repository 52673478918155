import { Component, OnInit } from '@angular/core';
import { I18nService } from './_i18n/i18n.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private _i18nService: I18nService) {}

  ngOnInit(): void {
    this._i18nService.setTranslation(this._i18nService.getCulture());
  }
}
