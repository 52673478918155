<div class="ui-fluid mb-3">
  <label *ngIf="label" [ngClass]="{'required': required}" [for]="identifier" style="inline-size: max-content">{{ label }}</label>
  <br />
  <p-calendar [(ngModel)]="_dateValue"
              (ngModelChange)="onModelChange($event)"
              [inputStyle]="{ 'height':'36px', 'border-color': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors ? '#dc3545' : '' }"
              [style]="{ 'width':'100%' }"
              view="month"
              dateFormat="mm/yy"
              [showIcon]="true"
              [showButtonBar]="true"
              [required]="required"
              [disabled]="disabled">
  </p-calendar>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
