import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalizedDatePipe } from './_pipes/localized-date.pipe';
import { JapaneseValueValidator } from './_validators/japanese-validator';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    LocalizedDatePipe,
    JapaneseValueValidator

  ],
  exports: [
    LocalizedDatePipe,
    JapaneseValueValidator
  ]
})
export class SharedModule { }
