<li class="nav-item dropdown pe-3">
  <a class="nav-link position-relative" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    <i id="bell" class="fas fa-bell fa-2x text-black" title="{{ 'LABEL_NOTIFICACOES' | translate }}"></i>
    <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" *ngIf="newCount && newCount > 0">
      {{ newCount }}
    </span>
  </a>
  <ul class="dropdown-menu dropdown-menu-end shadow" style="width: 350px; max-height: 500px; overflow-y: scroll;" aria-labelledby="navbarDropdown">
    <li *ngIf="!notificacoes || notificacoes.length < 1">
      <div class="notification">
        {{'LABEL_NAOHANADAPORAQUI' | translate }}
      </div>
    </li>
    <li *ngFor="let item of notificacoes">
      <div class="notification p-2" [ngStyle]="{ 'cursor:pointer' : item.payload && item.payload.relatorioId }"
           (click)="onClickNotification(item)">

        <div class="centered-row">
          <div *ngIf="item.sucesso && item.status == 0" class="spinner-border spinner-border-sm icon" role="status">
            <span class="sr-only">{{ 'LABEL_CARREGANDO' | translate}}</span>
          </div>
          <i *ngIf="item.sucesso && item.status == 1" class="fas fa-check-circle text-success pb-0 icon" style="padding-top: 3px;"></i>
          <i *ngIf="item.sucesso && item.status == 2" class="fas fa-exclamation-triangle text-warning pb-0 icon" style="padding-top: 3px;"></i>
          <i *ngIf="!item.sucesso" class="fas fa-times-circle text-danger pb-0 icon"></i>
          <p class="titulo">{{item.titulo.resourceKey | translate:item.titulo.formatArgs}}</p>
          <i class="fas fa-times close" (click)="deleteNotification($event, item)"></i>
        </div>

        <div>
          <p id="mensagem" class="mb-1 text-gray">{{item.mensagem.resourceKey | translate:item.titulo.formatArgs}}</p>
          <p *ngIf="item.payload && item.payload.descricao" class="mb-2 text-gray-sm">
            {{item.payload.descricao}}
          </p>
          <div *ngIf="item.payload && item.payload.relatorioId" class="centered-row mb-0 baixar">
            <i class="fas fa-arrow-down pb-0 mb-0 ms-0 me-1 mt-1" style="font-size: 0.8rem;"></i>
            <p class="mb-0">{{'LABEL_BAIXAR' | translate}} </p>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-end">
          <span class="text-gray-sm">{{item.dataCriacao | date:'short' }}</span>
        </div>
      </div>
    </li>
    <!--<li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><hr class="dropdown-divider"></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>-->
  </ul>
</li>
