import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IitemMenu } from './IitemMenu';

@Component({
  selector: 'sub-item-menu',
  templateUrl: './sub-item-menu.component.html',
  styleUrls: ['./sub-item-menu.component.css']
})
export class SubItemMenuComponent implements OnInit {

  @Input() items: IitemMenu[] = [];

  ngOnInit() { }

}

