import { Component, Inject, Input, Optional, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-area',
  templateUrl: "./form-area.component.html",
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormAreaComponent, multi: true }
  ]
})
export class FormAreaComponent extends ElementBase<string> {
  @Input() maxlength?: number;
  public identifier = `form-area-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

}

let identifier = 0;
