<div class="ui-fluid mb-3">
  <label *ngIf="label" [ngClass]="{'required': required}" [for]="identifier" style="inline-size: max-content">{{ label }}</label>
  <br />
  <p-calendar [(ngModel)]="value"
              [inputStyle]="{ 'height':'36px', 'border-color': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors ? '#dc3545' : '' }"
              [style]="{ 'width':'100%' }"
              view="year"
              dateFormat="yy"
              [required]="required"
              [disabled]="disabled"
              [showIcon]="true"
              [showButtonBar]="true"></p-calendar>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
