import { GrauKumite } from '../../_models/enum/grau-kumite';
import { SituacaoKumite } from '../../_models/enum/situacao-kumite';

export class FormKumiteFilter {
  termo: string;
  id: string;
  ids: string[];
  regionalIds: string[];
  situacoes: SituacaoKumite[];
  inativo = false;
  grausOmitama: GrauKumite[];
  kumiteApresentadorId: string;
  numeroSeminario: string;
  aplicarVisibilidade = true;
  permiteSaikenshu: boolean;
  paisId: string;
}

