import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';
import { HttpParamsHelper } from '../../../_helpers/http-params.helper';
import { INotification } from '../../../_interfaces/INotification';
import { StatusNotification } from '../../../_models/enum/status-notification';
import { AuthService } from '../../../_services/auth.service';
import { NotificationService } from '../../../_services/notification.service';
import { RelatorioService } from '../../../_services/relatorio.service';

declare const $: any;
const exportarGatewayUrl = `${AuthService.config.GatewayApi}/relatorio/exportar`;

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, OnDestroy {

  notificacoes: INotification[] = [];
  newCount: any = 0;
  diffTime: Date;

  constructor(private _authService: AuthService,
    private _oidcSecurityService: OidcSecurityService,
    private _translateService: TranslateService,
    private _RelatorioService: RelatorioService,
    private _notificationService: NotificationService) {
  }

  async ngOnInit() {
    this.notificationSubscription();
    this.getUserNotifications();
    this.loadJQuery();
  }

  ngOnDestroy() {
    this._notifySubscription.unsubscribe();
  }

  _notifySubscription: Subscription;
  _notifySubscriptionAsync: Subscription;
  notificationSubscription(): void {
    this._notifySubscription = this._notificationService.getNotification()
      .subscribe(msg => { this.addNotification(msg); });
  }

  downloadSeminarioExportado(fileName) {
    this._authService.post(`${exportarGatewayUrl}/GetSeminarioExportado`, fileName)
      .subscribe(result => {
        this._RelatorioService.download(result);
      });
  }

  loadJQuery() {
    $(document).ready(function () {
      $('.notBtn').on('mouseenter', function (e) {
        if ($('button.dropdown-toggle').next().css("display") == 'block') {
          $('button.dropdown-toggle').next().css("display", 'none');
        }
      });
    });
  }

  getUserNotifications() {
    this._authService.get(`${AuthService.config.GatewayApi}/notification/GetUserNotifications`)
      .subscribe(result => {
        this.notificacoes = result.notifications;
        for (let i = 0; i < this.notificacoes.length; i++) {
          this.notificacoes[i].dataCriacao = new Date(this.notificacoes[i].dataCriacao);
        }

        this.newCount = this.notificacoes.length;
        this.sortNotifications();
      });
  }

  addNotification(notification: INotification) {
    var aux = this.notificacoes.findIndex(t => t.correlationId == notification.correlationId);

    if (aux > -1)
      this.notificacoes.splice(aux, 1);

    this.notificacoes.push(notification);
    this.newCount = this.notificacoes.length;
    this.sortNotifications();

    var keys: string[] = [notification.mensagem.resourceKey, notification.titulo.resourceKey];
    this._translateService.get(keys, [notification.mensagem.formatArgs, notification.titulo.formatArgs])
      .subscribe(translations => {
        if (notification.sucesso) {
          if (notification.status == StatusNotification.Concluido) {
            this._notificationService.success(translations[keys[0]], translations[keys[1]]);
          }
          else if (notification.status == StatusNotification.ConcluidoAlerta) {
            this._notificationService.warning(translations[keys[0]], translations[keys[1]]);
          }
        }
        else if (!notification.sucesso) {
          console.debug(notification);
          this._notificationService.error(translations[keys[0]], translations[keys[1]]);
        }
      });
  }
  

  sortNotifications() {
    this.notificacoes.sort((a, b) => {
      a.dataCriacao = new Date(a.dataCriacao);
      b.dataCriacao = new Date(b.dataCriacao);
      return (a.dataCriacao.getTime() < b.dataCriacao.getTime()) ? 1 : -1;
    });
  }

  async onClickNotification(item) {

    if (item.payload && item.payload.relatorioId) {

      let relatorioId = item.payload.relatorioId;
      const token: string = await this._oidcSecurityService.getAccessToken().toPromise();
      let uri = `${AuthService.config.GatewayApi}/Relatorio/${relatorioId}?access_token=${token}`

      window.open(uri, '_blank').focus();

      //this._authService.get(`${AuthService.config.GatewayApi}/Relatorio/${relatorioId}`)
      //  .subscribe(result => {

      //    const byteCharacters = atob(result.contentBase64);
      //    const byteNumbers = new Array(byteCharacters.length);
      //    for (let i = 0; i < byteCharacters.length; i++) {
      //      byteNumbers[i] = byteCharacters.charCodeAt(i);
      //    }
      //    const byteArray = new Uint8Array(byteNumbers);

      //    const blob = new Blob([byteArray], { type: result.contentType });
      //    const blobUrl = URL.createObjectURL(blob);

      //    //Destroi a url depois de 10 minutos.
      //    setTimeout(() => URL.revokeObjectURL(blobUrl), 300000);

      //    window.open(blobUrl, '_blank').focus();
      //  });
    }
  }


  diffDate(date) {

    let difSec = (Date.now() - Date.parse(date)) / 1000;
    let difMin = difSec / (60);
    let difHr = difSec / (3600);
    let difDay = difSec / (3600 * 24);
    let difWeek = difSec / (3600 * 168);
    var translate = ['LABE_SEGUNDO', 'LABEL_SEGUNDOS', 'LABEL_MINUTO', 'LABEL_MINUTOS', 'LABEL_HORA', 'LABEL_HORAS', 'LABEL_DIA', 'LABEL_DIAS', 'LABEL_SEMANA', 'LABEL_SEMANAS'];

    if (difSec < 60) {
      return Math.floor(difSec)
    } else if (difMin < 60) {
      return Math.floor(difMin)
    } else if (difHr < 24) {
      return Math.floor(difHr)
    } else if (difDay < 7) {
      return Math.floor(difDay)
    } else if (difWeek < 4) {
      return Math.floor(difWeek)
    } else { return (Intl.DateTimeFormat('pt-BR').format(Date.parse(date))) }
  };

  /*markAsRead(item: any) {
    console.debug(item);
    for (var i = 0; i < this.notificacoes.length; i++) {
      if (this.notificacoes[i].id == item.id && this.notificacoes[i].isRead == false) {
        this._authService.get(`${AuthService.config.GatewayApi}/notification/MarkAsRead`, HttpParamsHelper.addQueryString({ notificationId: item.id }))
          .subscribe(result => {
            this.notificacoes[i].isRead = true;
            if (this.newCount > 0) { this.newCount -= 1; }
          });
        break;
      }
    }
  }*/

  deleteNotification(event: MouseEvent, item: INotification) {
    event.stopPropagation();
    for (var i = 0; i < this.notificacoes.length; i++) {
      if (this.notificacoes[i].correlationId == item.correlationId) {
        if (this.newCount > 0) { this.newCount -= 1; }
        this.notificacoes.splice(i, 1);
        this._authService.get(`${AuthService.config.GatewayApi}/notification/Delete`, HttpParamsHelper.addQueryString({ notificationId: item.correlationId })).subscribe();
        break;
      }
    }
  }

}
