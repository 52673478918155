<div class="mb-3">
  <label *ngIf="label" [ngClass]="{'required': required}" [for]="identifier" style="inline-size: max-content">{{ label }}</label>
  <input [id]="identifier" type="text" class="form-control" [prefix]="_prefix"
         name="identifier" [(ngModel)]="value" style="height:36px;" (change)="onChange($event)"
         [mask]="_mask" [dropSpecialCharacters]="dropSpecialCharacters" [showMaskTyped]="showMaskTyped"
         [disabled]="disabled" [readonly]="disabled" [required]="required" [autofocus]="autofocus"
         [ngClass]="{ 'is-invalid': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors,
         'input-group-lg': inputLarge}" />
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
