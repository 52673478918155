import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {

  @Input() show: boolean = false;
  @Input() class: string;

  constructor() { }

  ngOnInit() {
  }

}
