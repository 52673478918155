<ng-container *ngIf="erros && erros.length">
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-warning" role="alert">
        <ng-container *ngIf="titulo">
          <i class="fa fa-exclamation"></i><strong>{{ titulo | translate}}</strong>
        </ng-container>
        <div *ngFor="let msg of erros; let i = index">
          <span>{{ i+1 }}. {{ msg | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
