import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';


@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private messageService: MessageService,
    private oidcSecurityService: OidcSecurityService,
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpError: HttpErrorResponse) => {
        switch (httpError.status) {
          case 400:
            window.scrollTo(0, 0);
            return throwError(httpError.error);
            //const erros = [];
            //switch (typeof (httpError.error)) {
            //  case 'string':
            //    erros.push(httpError.error);
            //    break;
            //  case 'object':
            //    if (Array.isArray(httpError.error)) {
            //      for (const field in httpError.error) {
            //        erros.push(httpError.error[field]);
            //      }
            //    } else {
            //      if (httpError.error.errors) {
            //        for (const key in httpError.error.errors) {
            //          const value = httpError.error.errors[key];
            //          if (Array.isArray(value)) {
            //            Object.keys(value).forEach(key => {
            //              const msg = value[key];
            //              erros.push(msg);
            //            });
            //          } else {
            //            erros.push(value);
            //          }
            //        }
            //      } else {
            //        for (const key in httpError.error) {
            //          const value = httpError.error[key];
            //          if (Array.isArray(value)) {
            //            Object.keys(value).forEach(key => {
            //              const msg = value[key];
            //              erros.push(msg);
            //            });
            //          } else {
            //            erros.push(value);
            //          }
            //        }
            //      }
            //    }
            //    break;
            //  default:
            //    break;
            //}

            //if (erros.length) {
            //  window.scrollTo(0, 0);
            //  return throwError(erros);
            //}
            break;
          case 401:
            this.messageService.warning('Ops! A sessão expirou. Refaça o login')
              .then(() => {
                this.oidcSecurityService.authorize();
              });
            break;
          case 403:
            this.messageService.warning('Ops! Você não tem autorização para realizar a operação');
            break;
          case 500:
            this.messageService.error('Ops! houve um problema ao se comunicar com o servidor. Tente novamente mais tarde!');
            break;
          default:
            break;
        }

        return throwError(httpError);
      }));
  }

}
