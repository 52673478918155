import { Component, Inject, Input, Optional, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormCheckboxComponent, multi: true }
  ]
})
export class FormCheckboxComponent extends ElementBase<boolean>  {
  @Input() switch = false;
  public identifier = `form-checkbox-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

}

let identifier = 0;
