import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Constants } from '../../../constants';


@Component({
  selector: 'form-paginator',
  templateUrl: "./form-paginator.component.html",
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormPaginatorComponent, multi: true }
  ]
})
export class FormPaginatorComponent{

  public identifier = `form-text-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  @Input() totalRecords: number = 0;
  @Input() rowsPerPageOptions: number[] = Constants.quantidadeItensPorPagina;
  @Input() rows: number = this.rowsPerPageOptions[0];

  @Output('onPageChange') paginar = new EventEmitter();

  constructor() {
  }

  _paginar(e) {
    this.paginar.emit(e);
  }
}

let identifier = 0;
