
import { AfterViewInit, Component, ContentChild, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../../_services/auth.service';
import { FormSelectFilter } from './form-select.models';


@Component({
  selector: 'form-select',
  templateUrl: './form-select.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormSelectComponent), multi: true },
  ]
})

export class FormSelectComponent implements ControlValueAccessor, OnInit {

  @ViewChild('ngSelect') select;

  @Input() form: NgForm;
  @Input() label: string;
  @Input() required: boolean;
  @Input() virtualScroll: boolean;
  @Input() disabled: boolean;
  @Input() clearable = true;
  @Input() displayField = 'nome';
  @Input() valueField = null;
  @Input() loading = false;
  @Input() name: string;
  @Input() keyDownFn = (_: KeyboardEvent) => true;

  @ContentChild(TemplateRef) optionTemplate: TemplateRef<any>;

  @Input()
  public get items(): [] {
    return this._items$.value;
  }
  public set items(value: []) {
    this._items$.next(value);
  }

  @Input()
  public get filter(): FormSelectFilter {
    return this._filter$.value;
  }
  public set filter(value: FormSelectFilter) {
    this._filter$.next(value);
  }


  _originalTypeahead$: Subject<string>;
  @Input()
  public get typeahead(): Subject<string> {
    return this._originalTypeahead$;
  }
  public set typeahead(value: Subject<string>) {
    this.initializeTypeahead(value);
  }

  @Output('change') changeEvent = new EventEmitter();

  _errorMessage: string;
  _componenteInicializado: boolean;
  _identifier = `form-select-${identifier++}`;
  _items$ = new BehaviorSubject(null);

  _typeahead$ = new Subject<string>();
  _filter$ = new BehaviorSubject<FormSelectFilter>(null);

  constructor(private _authService: AuthService) {
    this.required = false;
    this.virtualScroll = false;
  }

  ngOnInit(): void {
    this.initializeFilter();
  }

  initializeFilter() {
    this._filter$.subscribe(filter => {
      if (filter && filter.url) {
        this.loading = true;
        this._authService.post(filter.url, filter.body ?? {}).subscribe(result => {
          this._items$.next(result.documents);
          this.loading = false;
        });
      }
    });
  }

  initializeTypeahead(value: Subject<string>) {
    if (!value) {
      this._typeahead$ = null;
    }
    else {
      this._originalTypeahead$ = value;
      this._typeahead$ = new Subject<string>();
      this._typeahead$
        .pipe(
          debounceTime(500),
          distinctUntilChanged())
        .subscribe(termo => {
          if (this._originalTypeahead$)
            this._originalTypeahead$.next(termo);
        });
    }
  }

  _value: string;

  public get getSelectComponent(): NgSelectComponent {
    return this.select;
  }

  public get value(): string {
    return this._value;
  }

  public set value(v) {
    this._value = v;
    this._onChange(this._value);
    this._onTouched();
  }

  _ngModelChanged(evt) {
    this._onChange(evt);
  }

  _itemChanged(evt) {
    this.changeEvent.emit(evt);
  }

  //ControlValueAccessor Interface
  writeValue(obj: string): void {
    this._value = obj;
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _onChange: any = () => { };
  _onTouched: any = () => { };

}
let identifier = 0;
