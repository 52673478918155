import { getLocaleNumberFormat, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Component, Inject, Optional, OnInit, ViewChild, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgModel } from '@angular/forms';
import { I18nService } from '../../_i18n/i18n.service';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-currency',
  templateUrl: './form-currency.component.html',
  styleUrls: ['./form-currency.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormCurrencyComponent, multi: true }
  ]
})
export class FormCurrencyComponent extends ElementBase<string> implements OnInit {

  public identifier = `form-currency-${identifier++}`;

  @Input()
  public get options(): any {
    return this._options;
  }
  public set options(value: any) {
    this.setOptions(value);
  }
  _options: any;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  decimalSymbol: string;
  thousandSymbol: string;

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.resetInputValidation();
    }
  }
  
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private _i18nService: I18nService) {
    super(validators, asyncValidators);
    this.decimalSymbol = getLocaleNumberSymbol(this._i18nService.getCulture(), NumberSymbol.CurrencyDecimal);
    this.thousandSymbol = getLocaleNumberSymbol(this._i18nService.getCulture(), NumberSymbol.CurrencyGroup);
  }

  ngOnInit(): void {
    if (this._options)
      this.setOptions(null);
  }

  resetInputValidation() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }

  setOptions(options) {
    if (options) {
      options.decimal = this.decimalSymbol;
      options.thousands = this.thousandSymbol;
      options.prefix = "";
      this._options = options;
    } else {
      this._options = {
        decimal: this.decimalSymbol,
        thousands: this.thousandSymbol,
        precision: 2,
        prefix:""
      }
    }
  }
}

let identifier = 0;
