
<nav class="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse dual-nav w-25 order-1 order-md-0">
      
    </div>

    <div class="navbar-brand mx-auto d-block text-center order-0 order-md-1 w-50">
      <img src="../../assets/img/sistemamahikari.png" [routerLink]="['/home']" alt="{{ 'TITULO_SISTEMAMAHIKARI' | translate }}" />
    </div>

    <div class="navbar-collapse collapse dual-nav w-25 order-2" id="navbarSupportedContent">
      <ul class="navbar-nav align-items-end ms-auto">
        <accordion></accordion>
        <notification></notification>
        <li class="nav-item dropdown">
          <div role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <img *ngIf="userPicture" src="{{ userPicture }}" (error)="onImageBreak()" class="profile-image"
                 alt="profile-image">
            <div *ngIf="!userPicture" class="profile-image">
              {{ usuarioNomeIniciais }}
            </div>
          </div>
          <div class="dropdown-menu dropdown-menu-end p-2 pt-4 shadow" style="width:250px">
            <div *ngIf="userPicture" class="profile-image-lg mx-auto" role="button" data-bs-toggle="modal" data-bs-target="#modalProfilePic">
              <img src="{{ userPicture }}" (error)="onImageBreak()" class="profile-image-lg"
                   alt="profile-image-lg">
            </div>
            <div *ngIf="!userPicture" role="button" class="profile-image-lg mx-auto" data-bs-toggle="modal" data-bs-target="#modalProfilePic">
              <span class="align-middle">{{ usuarioNomeIniciais }}</span>
            </div>

            <div class="text-center pt-2">
              <h4>{{ usuario?.nomeCompleto }}</h4>
              <p class="text-muted">{{ 'LABEL_DAREGIONALDE' | translate }} {{ usuario?.nomeRegional }}</p>
            </div>

            <div class="dropdown pt-2">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                <span>{{ 'LABEL_IDIOMA' | translate }}</span>
                <img [src]="getCurrentCulture()?.icone" class="ms-2" />
              </button>
              <ul class="dropdown-menu sub-menu" style="margin-right: 25px">
                <li *ngFor="let c of cultures">
                  <a class="dropdown-item" [hidden]="c.id == getCurrentCulture()?.id" href="javascript:void(0)"
                     (click)="setTranslation(c.id)">
                    <img [src]="c.icone" class="me-2" />
                    <span>{{ c.nome }}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div class="pt-2 dropdown">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                <span>{{ 'LABEL_PAIS' | translate }}</span>
                <span class="ms-1">{{ paisSelecionado.nome }}</span>
              </button>
              <ul *ngIf="paises.length > 0" class="dropdown-menu sub-menu col" style="margin-right: 25px">
                <li *ngFor="let pais of paises">
                  <a class="dropdown-item" href="javascript:void(0)"
                     (click)="paisChanged(pais.id)">
                    <span>{{ pais.nome }}</span>
                  </a>
                </li>
              </ul>
            </div>

            <div class="align-right">
              <button type="button" class="btn btn-outline-danger" (click)='logoff()'>
                <i class="fa fa-sign-out"></i>
                <span>{{ 'LABEL_SAIR' | translate }}</span>
              </button>
            </div>

          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Modal edit profile picture -->
<div class="modal fade" id="modalProfilePic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <button type="button" id="btnProfileImg" class="btn btn-primary btn-sm btn-block" onClick="javascript:document.getElementById('file-input-profile').click()">
          {{ 'BOTAO_SELECIONARFOTO' | translate }}
        </button>
        <input type="file" id="file-input-profile" (change)="fileChangeEvent($event)" hidden accept=".jpg, .png, .jpeg, .gif|image/*" />

        <hr />
        <div class="d-flex justify-content-around">
          <div>
            <label>{{ 'LABEL_PREVIEW' | translate }}</label>
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                           [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="1/1" [resizeToWidth]="200"
                           [cropperMinWidth]="1" [cropperMaxWidth]="0" [onlyScaleDown]="true" [roundCropper]="false"
                           [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                           [style.display]="showCropper ? null : 'none'" format="png,jpeg" (imageCropped)="imageCropped($event)"
                           (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
                           style="width: 310px;"></image-cropper>
          </div>
          <div>
            <label>{{ 'LABEL_PROFILE' | translate }}</label>
            <div>
              <img [src]="profileImage" *ngIf="profileImage" class="rounded-circle img-thumbnail" alt="profile-image"
                   [style.border]="profileImage ? '1px solid black' : 'none'">
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div>
          <button type="button" class="btn btn-danger" (click)="removerFoto()">{{ 'BOTAO_REMOVERFOTO' | translate }}</button>
        </div>
        <div>
          <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">
            {{ 'BOTAO_CANCELAR' | translate}}
          </button>
          <button type="button" class="btn btn-primary" (click)="alterarFoto()" data-bs-dismiss="modal">
            {{ 'BOTAO_SALVAR' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal selececionar pais -->
<div class="modal fade" id="modalSelecionaPais" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-start">

        <form-select label="{{ 'LABEL_PAIS' | translate }}" name="paisSelecionado" [disabled]="paises.length < 1"
                     [(ngModel)]="paisSelecionado" [items]="paises" displayField="nome">
        </form-select>

      </div>
      <div class="modal-footer">
        <button type="button" (click)="salvarPaisSelecionado()" [disabled]="!paisSelecionado?.id" data-bs-dismiss="modal"
                class="btn btn-primary">
          {{ 'LABEL_SALVAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
