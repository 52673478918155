<div class="mb-3">
  <label *ngIf="label" [ngClass]="{'required': required}" [for]="identifier">{{ label }}</label>
  <p-calendar [id]="identifier"
              [ngModel]="_dateValue"
              (ngModelChange)="onModelChange($event)"
              [timeOnly]="true"
              [hourFormat]="24"
              [showIcon]="true"
              [inputStyle]="{ 'height':'36px', 'border-color': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors ? '#dc3545' : '' }"
              [style]="{ 'margin-top': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors ? '.25rem' : '0rem', 'width':'100%' }"
              [disabled]="disabled"
              [showSeconds]="showSeconds"
              [required]="required"></p-calendar>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
