<div class="mb-3">
  <label *ngIf="label" [ngClass]="{'required': required}" [for]="identifier">{{ label }}</label>
  <div class="input-group">
    <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" [disabled]="disabledDropdown || (currencies && currencies.length == 1)">
      <strong>{{ getMoedaSimbolo() }}</strong>
    </button>
    <ul class="dropdown-menu">
      <li class="dropdown-item" *ngFor="let c of currencies" (click)="selectItem(c)" [hidden]="currency == c.code">
        {{ c.symbol }}
      </li>
    </ul>
    <input [id]="identifier" class="form-control" currencyMask
           [(ngModel)]="value" name="formCurrencyGroup" style="height:36px"
           [options]="mask" [disabled]="disabled" [required]="required"
           [ngClass]="{ 'is-invalid': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors}" />

  </div>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
