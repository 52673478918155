import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthInterceptor, AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';
import { SharedModule } from './shared.module';
import { FormModule } from './_forms/form.module';
import { I18nService } from './_i18n/i18n.service';
import { HttpRequestInterceptor } from './_interceptor/http-request.interceptor';
import { SelecaoPaisComponent } from './_layouts/secure/selecao-pais.component';
import { PublicComponent } from './_layouts/public/public.component';
import { AccordionComponent } from './_layouts/secure/accordion/accordion.component';
import { NavHeaderComponent } from './_layouts/secure/nav-header/nav-header.component';
import { NavMenuComponent } from './_layouts/secure/nav-menu/nav-menu.component';
import { SubItemMenuComponent } from './_layouts/secure/nav-menu/sub-item-menu/sub-item-menu.component';
import { NotificationComponent } from './_layouts/secure/notification/notification.component';
import { SecureComponent } from './_layouts/secure/secure.component';
import { UserMenuComponent } from './_layouts/secure/user-menu/user-menu.component';
import { AuthService } from './_services/auth.service';
import './_models/date.extensions';
import { KumiteAceiteComponent } from './_features/pessoa/kumite/aceite/kumite-aceite.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    SubItemMenuComponent,
    UserMenuComponent,
    NotificationComponent,
    AccordionComponent,
    NavHeaderComponent,
    SelecaoPaisComponent,
    PublicComponent,
    SecureComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    FormModule,
    NgxSkeletonLoaderModule,
    AuthConfigModule,
    RouterModule.forRoot([
      { path: 'pessoa/kumite/aceite/:hash', component: KumiteAceiteComponent },
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: '', component: SecureComponent, canActivate: [AutoLoginAllRoutesGuard], children: [
          { path: 'home', loadChildren: () => import('./_features/home/home.module').then(m => m.HomeModule) },
          { path: 'organizacao', loadChildren: () => import('./_features/organizacao/organizacao.module').then(m => m.OrganizacaoModule) },
          { path: 'pessoa', loadChildren: () => import('./_features/pessoa/pessoa.module').then(m => m.PessoaModule) },
          { path: 'financeiro', loadChildren: () => import('./_features/financeiro/financeiro.module').then(m => m.FinanceiroModule) },
          { path: 'solicitacoes', loadChildren: () => import('./_features/solicitacoes/solicitacoes.module').then(m => m.SolicitacoesModule) },
          { path: 'seminarios', loadChildren: () => import('./_features/seminarios/seminarios.module').then(m => m.SeminariosModule) },
          { path: 'gosaika', loadChildren: () => import('./_features/gosaika/gosaika.module').then(m => m.GosaikaModule) },
          { path: 'circular', loadChildren: () => import('./_features/circular/circular.module').then(m => m.CircularModule) },
          { path: 'template', loadChildren: () => import('./_features/template/template.module').then(m => m.TemplateModule) },
          { path: 'localidade', loadChildren: () => import('./_features/localidade/localidade.module').then(m => m.LocalidadeModule) },
          { path: 'relatorio', loadChildren: () => import('./_features/relatorio/relatorio.module').then(m => m.RelatorioModule) }
        ]
      },
      { path: '', component: PublicComponent },
      { path: '**', redirectTo: '' }
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: I18nService,
        deps: [AuthService]
      }
    }),
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    ImageCropperModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: (i18nService: I18nService) => i18nService.getCulture()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
