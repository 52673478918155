import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class RelatorioService {
  async getUrl(relatorio: Relatorio) {
    const byteCharacters = atob(relatorio.contentBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: relatorio.contentType });
    const blobUrl = URL.createObjectURL(blob);

    //Destroi a url depois de 10 minutos.
    setTimeout(() => URL.revokeObjectURL(blobUrl), 300000);

    return blobUrl;
  }

  async download(relatorio: Relatorio) {
    var a = document.createElement("a");
    a.href = await this.getUrl(relatorio);
    a.setAttribute("download", relatorio.fileName);
    a.click();
  }
}

interface Relatorio {
  fileName: string;
  contentLength: string;
  contentType: string;
  contentBase64: string;
}
