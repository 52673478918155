import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static config = { GatewayApi: `${window.location.origin}/api` };

  constructor(private http: HttpClient, private _router: Router, private oidcSecurityService: OidcSecurityService) {
  }

  get(url: string, params?: HttpParams): Observable<any> {
    return this.http.get(url, { headers: this.getHeaders(), params: params });
  }
  put(url: string, data: any, params?: HttpParams): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.put(url, body, { headers: this.getHeaders(), params: params });
  }
  patch(url: string, data: any, params?: HttpParams): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.patch(url, body, { headers: this.getHeaders(), params: params });
  }
  post(url: string, data: any, params?: HttpParams, responseType?, observe?): Observable<any> {
    const body = JSON.stringify(data);
    return this.http.post(url, body, {
      headers: this.getHeaders(),
      params: params,
      responseType: responseType ? responseType : 'json',
      observe: observe ? observe : 'body'
    });
  }
  delete(url: string, params?: HttpParams): Observable<any> {
    return this.http.delete(url, { headers: this.getHeaders(), params: params });
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    //headers = this.appendAuthHeader(headers);
    headers = this.appendLanguageHeader(headers);
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    return headers;
  }

  //private appendAuthHeader(headers: HttpHeaders) {
  //  const token = this.getUsuarioToken();
  //  if (!token) return headers;
  //  return headers.set('Authorization', `Bearer ${token}`);
  //}

  private appendLanguageHeader(headers: HttpHeaders) {
    const cultura = JSON.parse(localStorage.getItem('Culture'));
    if (cultura) {
      headers = headers.set('Accept-Language', cultura);
    }

    const pais = localStorage.getItem('PaisId');
    if (pais) {
      headers = headers.set('X-PaisId', pais);
    }

    return headers;
  }

  //getUsuarioToken(): any {
  //  var token: any = null;
  //  this.oidcSecurityService.getAccessToken().toPromise(t => token = t);
  //  return token;
  //}

  //getIsAuthorized() {
  //  return this.getUser() != null && !this.getUser().expired;
  //}

  //signIn() {
  //  AuthService.manager.signinRedirect();
  //}

  //signOut() {
  //  localStorage.removeItem('User');
  //  AuthService.manager.signoutRedirect();
  //}

  //completeAuthentication() {
  //  AuthService.manager.signinRedirectCallback().then(async user => {
  //    localStorage.setItem('User', user.toStorageString());
  //    this._router.navigate(['/']);
  //  });
  //}


}


