/* eslint-disable arrow-body-style */
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';

export const httpLoaderFactory = (httpClient: HttpClient) => {
  const config$ = httpClient.get<any>(`${window.location.origin}/api/configuration/oidc`).pipe(
    map((customConfig: any) => {

      const param = new URLSearchParams(window.location.search);

      return {
        authority: customConfig.authority,
        redirectUrl: window.location.origin,
        clientId: customConfig.client_id,
        responseType: customConfig.response_type,
        scope: customConfig.scope,
        postLogoutRedirectUri: window.location.origin,
        useRefreshToken: false,
        silentRenew: false,
        silentRenewUrl: `${window.location.origin}/silent-renew`,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        postLoginRoute: '/home',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/unauthorized',
        logLevel: LogLevel.Warn,
        secureRoutes: [window.location.origin],
        startCheckSession: true,
        triggerAuthorizationResultEvent: true,
        disableIatOffsetValidation: true,
        customParamsAuthRequest: param.get('Parameters') ? {
          acr_values: param.get('Parameters')
        } : null,
      };
    })
  );

  return new StsConfigHttpLoader(config$);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      }
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }
