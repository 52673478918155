import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgForm, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'form-time',
  templateUrl: './form-time.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormTimeComponent, multi: true }
  ]
})
export class FormTimeComponent implements ControlValueAccessor, OnInit {

  public identifier = `form-time-${identifier++}`;
  @Input() form: NgForm;
  @Input() label: string;
  @Input() name: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() showSeconds: boolean;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  _dateValue: Date;

  onModelChange(value: Date) {
    if (this._dateValue !== value) {
      this._dateValue = value;
      this._onChange(this.getTimeString(value));
    }
  }

  ngOnInit() { }


  getTimeString(date: Date): string {
    if (date) {
      if (this.showSeconds)
        return date.toTimeString().split(' ')[0];
      else
        return date.toTimeString().split(' ')[0].substring(0, 5)
    }
    else {
      return null;
    }
  }

  getDateFromTimeString(str: string): Date {
    if (!str)
      return null;

    let date = new Date();
    var pattern = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])(:([0-5]?[0-9]))?$/;
    if (pattern.test(str)) {
      var groups = pattern.exec(str);
      const seconds = groups.length > 2 ? Number(groups[3]) : 0;
      date.setHours(Number(groups[0]), Number(groups[1]), seconds, 0);
    }
    else {
      date.setHours(0, 0, 0, 0);
      return date;
    }
  }


  //ControlValueAccessor Interface
  writeValue(obj: string): void {

    this._dateValue = this.getDateFromTimeString(obj);
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _onChange: any = () => { };
  _onTouched: any = () => { };
}

let identifier = 0;

