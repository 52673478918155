import { Component, Inject, Optional, Input, OnInit, ViewChild, EventEmitter, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-mask',
  templateUrl: './form-mask.component.html',
  styleUrls: ['./form-mask.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormMaskComponent) , multi: true }
  ]
})
export class FormMaskComponent extends ElementBase<string> {

  public identifier = `form-mask-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  _prefix: string = "";
  @Input() set prefix(value: string) {
    if (value !== this._prefix) {
      this._prefix = value;
    }
  }

  get prefix(): string {
    return this._prefix;
  }

  @Input() dropSpecialCharacters: boolean;
  @Input() showMaskTyped: boolean;
  @Output('change') changeEvent = new EventEmitter();

  _mask: string;
  @Input() set mask(value: string) {
    if (value !== this._mask) {
      this._mask = value;
    }
  }

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.reset();
    }
  }

 get mask(): string {
    return this._mask;
  } 

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  onChange(evt) {
    this.changeEvent.emit(evt);
  }

  reset() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }
}

let identifier = 0;
