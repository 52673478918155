<div class="ui-fluid mb-3">
  <ng-container *ngIf="label">
    <label [ngClass]="{'required': required}" [for]="identifier" style="inline-size: max-content">{{ label }}</label>
    <br />
  </ng-container>
  <p-calendar [(ngModel)]="_dateValue"
              (ngModelChange)="onModelChange($event)" 
              [inputStyle]="{ 'height':'36px', 'border-color':  form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors ? '#dc3545' : '' }"
              [style]="{ 'width':'100%' }"
              [showIcon]="true"
              [showButtonBar]="true"
              [showTime]="showTime"
              [required]="required"
              [disabled]="disabled"
              [minDate]="min"
              [maxDate]="max">
  </p-calendar>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
