import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgForm, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';



@Component({
  selector: 'form-month',
  templateUrl: './form-month.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormMonthComponent, multi: true }
  ]
})
export class FormMonthComponent implements ControlValueAccessor, OnInit {

  public identifier = `form-month-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  _dateValue: Date;

  @Input() form: NgForm;
  @Input() yearRange: string;
  @Input() showToday: boolean = true;
  @Input() showOnTop: boolean;
  @Input() showTime: boolean;
  @Input() showSeconds: boolean;
  @Input() label: string;
  @Input() name: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Output() public change = new EventEmitter();

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.reset();
    }
  }

  constructor() {
  }

  reset() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }

  ngOnInit() {

  }

  getUTCISOString(date: Date): string {
    if (date) {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString();
      const day = date.getDate().toString();
      const hour = '00';
      const minutes = '00';
      const seconds = '00';

      return `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T` +
        `${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    }
    else {
      return null;
    }
  }

  getDateFromString(str: string): Date {
    if (!str)
      return null;

    let date = new Date(str);
    return Number.isNaN(date) ? null : date;
  }

  onModelChange(value: Date) {
      this._onChange(this.getUTCISOString(value));
  }

  //ControlValueAccessor Interface
  writeValue(obj: string): void {

    this._dateValue = this.getDateFromString(obj);
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _onChange: any = () => { };
  _onTouched: any = () => { };

}

let identifier = 0;
