import { Component, Input, Inject, Optional, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-text',
  templateUrl: "./form-text.component.html",
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormTextComponent), multi: true }
  ]
})
export class FormTextComponent extends ElementBase<string> {

  public identifier = `form-text-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  @Input() maxlength?: number;
  @Input() minlength?: number;
    
  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;    
    if (value) {
     this.resetInputValidation();
    }
  }

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
   
  }

  resetInputValidation(){
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();    
  }
}

let identifier = 0;
