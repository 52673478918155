import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'vincular-paises',
  templateUrl: './vincular-paises.component.html',
  styleUrls: ['./vincular-paises.component.css']
})
export class VincularPaisesComponent implements OnInit {

  getPaisesUrl = `${AuthService.config.GatewayApi}/Lookup/GetPaises`;

  paises: any[] = [];

  @Input() paisesSelecionado: any[] = [];
  @Output() change = new EventEmitter<any[]>();

  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getPaises();

    if (!this.paisesSelecionado) {
      this.paisesSelecionado = [];
    }
  }

  getPaises() {
    this._authService.get(this.getPaisesUrl).subscribe(result => this.paises = result);
  }

  onAddItem(e) {
    this.change.emit(this.paisesSelecionado);
  }

  onRemoveItem(e) {
    this.change.emit(this.paisesSelecionado);
  }
}
