import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';

const gatewayUrl = `${AuthService.config.GatewayApi}/aviso`;

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {

  avisos: any;

  constructor(private _authService: AuthService ) { }

  ngOnInit(): void {
    this.getAvisos();
  }

  getAvisos() {
    this._authService.get(gatewayUrl).subscribe(result => this.avisos = result);
  }
}
