 <div *ngIf="form && (form.submitted || model.touched || model.dirty) && model.errors " class="invalid">
    <small *ngIf="model.errors.required">{{ 'MENSAGEM_CAMPOREQUERIDO' | translate }}</small>
    <small *ngIf="model.errors.pattern">{{ 'MENSAGEM_FORMATOINVALIDO' | translate }}</small>
    <small *ngIf="model.errors.minlength">O valor precisa ter no mínimo {{ model.errors.minlength.requiredLength }} caracteres</small>
    <small *ngIf="model.errors.maxlength">O valor precisa ter no máximo {{ model.errors.maxlength.requiredLength }} caracteres</small>
    <small *ngIf="model.errors.email">{{ 'MENSAGEM_EMAILINVALIDO' | translate }}</small>
    <small *ngIf="model.errors.cnpj">{{ 'MENSAGEM_CNPJINVALIDO' | translate }}</small>
    <small *ngIf="model.errors.cpf">{{ 'MENSAGEM_CPFINVALIDO' | translate }}</small>
    <small *ngIf="model.errors.jpPattern">{{ 'MENSAGEM_CAMPOJAPONESINVALIDO' | translate }}</small>
 </div>
