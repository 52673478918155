import { ChangeDetectorRef, Component, HostListener, KeyValueDiffer, KeyValueDiffers, NgZone, OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';
import { HttpParamsHelper } from '../../../_helpers/http-params.helper';
import { AuthService } from '../../../_services/auth.service';

const gatewayUrl = `${AuthService.config.GatewayApi}/menu`;
//import * as $ from 'jquery';
declare const $: any;

@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {

  buscando: boolean = false;

  itensMenu: any[] = [];

  constructor(private _authService: AuthService) {
  }

  ngOnInit() {
    this.getItensMenu();
    this.loadJs();
  }

  getItensMenu() {
    this.buscando = true;

    this._authService.get(gatewayUrl)
      .pipe(finalize(() => this.buscando = false))
      .subscribe(result => {
        this.itensMenu = result;
      });
  }

  loadJs() {
    $(document).ready(function () {

      //$('.dropdown-menu a.dropdown-toggle').on('click', function () {
      //  if (!$(this).next().hasClass('show')) {
      //    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
      //  }

      //  const $subMenu = $(this).next('.dropdown-menu');
      //  $subMenu.toggleClass('show');

      //  return false;
      //});

      //const observer = new MutationObserver((mutations) => {
      //  mutations.forEach((mutation) => {
      //    if (mutation.attributeName === "class") {
      //      const attributeValue = $(mutation.target).prop(mutation.attributeName);

      //      if (attributeValue.indexOf("show") < 0) {
      //        $('.dropdown-submenu .show').removeClass('show');
      //      }
      //    }
      //  });
      //});

      //$('li.nav-item.dropdown').toArray().forEach((li) => {
      //  observer.observe(li, {
      //    attributes: true
      //  });
      //});

      //$('ul.navbar-nav > .nav-item .dropdown').click(function () {
      //  //alert("Handler for .click() called.");
      //});

    });
  }

}
