export class Arquivo {
  id: string;
  nome: string;
  contentType: string;
  dados: string;
  data: Date;

  constructor(nome?: string, contentType?: string, dados?: string, data?: Date) {
    if (nome)
      this.nome = nome;
    if (contentType)
      this.contentType = contentType;
    if (dados)
      this.dados = dados;
    if (data)
      this.data = data;
  }

  static arquivoMap(arquivo: Arquivo): Arquivo {

    let novoArquivo = new Arquivo();

    if (arquivo.id)
      novoArquivo.id = arquivo.id;

    if (arquivo.nome)
      novoArquivo.nome = arquivo.nome;

    if (arquivo.contentType)
      novoArquivo.contentType = arquivo.contentType;

    if (arquivo.dados)
      novoArquivo.dados = arquivo.dados;

    if (arquivo.data)
      novoArquivo.data = arquivo.data;

    return novoArquivo;
  }

  static createFromJsonObject(json): Arquivo {

    if (!json)
      return null;

    let arquivo = new Arquivo();

    if (json.id)
      arquivo.id = json.id;

    if (json.nome)
      arquivo.nome = json.nome;

    if (json.contentType)
      arquivo.contentType = json.contentType;

    if (json.dados)
      arquivo.dados = json.dados;

    if (json.data)
      arquivo.data = json.data;

    return arquivo;
  }
}
