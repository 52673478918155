import { HttpParams } from "@angular/common/http";

export class HttpParamsHelper {
    static addQueryString(obj: Object): HttpParams {
        let params = new HttpParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const val = obj[key];
                if (val !== null && val !== undefined) {
                    if (val instanceof Date) {
                        params = params.append(key, val.toDateString());
                    } else if (val instanceof Array) {
                        val.forEach(item => {
                            if (item instanceof Date) {
                                params = params.append(key, item.toDateString());
                            } else {
                                params = params.append(key, item);
                            }
                        });
                    } else {
                        params = params.append(key, val.toString());
                    }
                }
            }
        }
        return params;
    }

}
