<div class="mb-3">
  <label *ngIf="label" [ngClass]="{'required': required}" [for]="identifier" style="inline-size: max-content">{{ label }}</label>
  <div>
    <input [id]="identifier" type="file" style="display: none" onclick="this.value = null" (change)="onFileSelected($event.target.files)"
           [accept]="acceptTypes" [attr.multiple]="multiple ? '' : false" />
    <label class="btn btn-primary" [for]="identifier" >Selecione um arquivo</label>
  </div>
  <div><small>Formatos permitidos:<i>({{ acceptTypes }}).</i> Máximo: {{ maxFileSize }}MB</small></div>
  <!--<div style="inline-size: max-content">
    <div class="invalid" *ngIf="InvalidTypeFile">O formato do arquivo está inválido</div>
    <div class="invalid" *ngIf="OverloadedSizeFile">O tamanho do arquivo está inválido</div>
  </div>-->
  <ng-container *ngFor="let i of value; let index = index">
    <div class="fileInfo d-flex justify-content-between py-2">
      <div class="file-name">
        <span><i class="fas fa-paperclip me-2"></i></span>
        <span>{{i.nome}}</span>
      </div>
      <span><a class="icon-cancel" (click)="onFileRemoved(i)"><i class="fas fa-times"></i></a></span>
    </div>
  </ng-container>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
