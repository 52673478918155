import { Component, Inject, Optional, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel, FormControl, NgControl, Validator } from '@angular/forms';


import { ElementBase } from '../base/element-base';
import { Arquivo } from '../../_models/arquivo';

@Component({
  selector: 'form-upload',
  templateUrl: './form-upload.component.html',
  styleUrls: ['./form-upload.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormUploadComponent, multi: true }
  ]
})
export class FormUploadComponent extends ElementBase<Arquivo[]> implements OnInit, Validator {
  public identifier = `form-upload-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  @Input() multiple?: boolean = false;
  @Input() acceptTypes: string = "";
  @Input() maxFileSize: number;
  @Output() fileUploaded: EventEmitter<Arquivo> = new EventEmitter<Arquivo>();

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  ngOnInit(): void {

  }

  onFileSelected(fileList: FileList): void {
    if (fileList && (fileList.length > 0)) {

      for (let i = 0; i < fileList.length; i++) {
        var file = fileList[i];

        let typeVerified = true;
        if (this.acceptTypes)
          typeVerified = this.verifyFileType(file.name.split(".").pop());

        let sizeVerified = true;
        if (this.maxFileSize) {
          sizeVerified = this.verifyFileSize(file.size);
        }

        if (typeVerified && sizeVerified) {

          if (!this.innerValue) this.innerValue = [];

          this.readFile(file, arquivo => {
            this.innerValue.push(arquivo);
            this.changed.forEach(f => f(this.innerValue))
            this.fileUploaded.emit(arquivo);
          })
        } else {
          this.fileUploaded.emit(null);
        }
      }
    } else {
      this.fileUploaded.emit(null);
    }
  }

  readFile(file, callback) {
    const reader = new FileReader();

    // Definir uma função de retorno de chamada para quando a leitura do arquivo for concluída
    reader.onload = function (e: any) {
      callback(new Arquivo(file.name, file.type, btoa(e.target.result), new Date()));
    };

    // Iniciar a leitura do arquivo como texto
    reader.readAsBinaryString(file);
  }


  onFileRemoved(arquivo: Arquivo): void {
    const index = this.innerValue.indexOf(arquivo, 0);
    if (index > -1) {
      this.innerValue.splice(index, 1);
      this.changed.forEach(f => f(this.innerValue));
    }
  }

  verifyFileType(type: string): boolean {
    const normalizedType = type.toLowerCase();
    const normalizedAcceptTypes = this.acceptTypes.toLowerCase();
    return normalizedAcceptTypes.indexOf(normalizedType) > -1;
  }

  verifyFileSize(size: number): boolean {
    return size <= (this.maxFileSize * 1000000);
  }

  public validate(c: FormControl) {
    if (this.required && (!this.innerValue || this.innerValue.length == 0))
      return { required: true };
  }
}

let identifier = 0;

