import { Component, Input } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'validation-messages',
  templateUrl: './validation-messages.component.html'
})

export class ValidationMessages {

  @Input() form: NgForm;
  @Input() model: NgModel;

  constructor() { }
}
