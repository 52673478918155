

<div *ngIf="form && (form.submitted || form.controls[controlName]?.dirty || form.controls[controlName]?.touched) && form.controls[controlName]?.invalid" class="invalid">
  <small *ngIf="form.controls[controlName].errors.required">{{ 'MENSAGEM_CAMPOREQUERIDO' | translate }}</small>
  <small *ngIf="form.controls[controlName].errors.pattern">{{ 'MENSAGEM_FORMATOINVALIDO' | translate }}</small>
  <small *ngIf="form.controls[controlName].errors.minlength">O valor precisa ter no mínimo {{ model.errors.minlength.requiredLength }} caracteres</small>
  <small *ngIf="form.controls[controlName].errors.maxlength">O valor precisa ter no máximo {{ model.errors.maxlength.requiredLength }} caracteres</small>
  <small *ngIf="form.controls[controlName].errors.email">{{ 'MENSAGEM_EMAILINVALIDO' | translate }}</small>
  <small *ngIf="form.controls[controlName].errors.cnpj">{{ 'MENSAGEM_CNPJINVALIDO' | translate }}</small>
  <small *ngIf="form.controls[controlName].errors.cpf">{{ 'MENSAGEM_CPFINVALIDO' | translate }}</small>
  <small *ngIf="form.controls[controlName].errors.jpPattern">{{ 'MENSAGEM_CAMPOJAPONESINVALIDO' | translate }}</small>
</div>
