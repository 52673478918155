<div class="ui-fluid mb-3">
  <label [ngClass]="{'required': required}" *ngIf="label">{{ label }}</label><br />
  <ng-select [id]="_identifier" [(ngModel)]="_value" (ngModelChange)="_ngModelChanged($event)" (change)="_itemChanged($event)"
             [bindLabel]="displayField" [bindValue]="valueField" #model="ngModel" [typeahead]="_typeahead$" [keyDownFn]="keyDownFn"
             [items]="_items$ | async" [hideSelected]="true" [loading]="loading" [clearable]="clearable"
             typeToSearchText="{{ 'MENSAGEM_DIGITEPARABUSCAR' | translate }}" #ngSelect
             notFoundText="{{ 'MENSAGEM_NAOENCONTRADO' | translate }}" [disabled]="disabled"
             [ngClass]="{ 'is-invalid': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors}"
             [required]="required" [virtualScroll]="virtualScroll">
    <ng-template *ngIf="optionTemplate" [ng-option-tmp] let-item="item">
      <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{item:item, index:index, searchTerm: searchTerm}">
      </ng-container>
    </ng-template>
    <ng-template ng-footer-tmp>
      <small style="color: #007ad9">{{ 'LABEL_RODAPE' | translate }}</small>
    </ng-template>
  </ng-select>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
