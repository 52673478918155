
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR } from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';
import { AuthService } from '../../_services/auth.service';
import { FormMikumiteFilter } from './form-mikumite.models';


@Component({
  selector: 'form-mikumite',
  templateUrl: './form-mikumite.component.html',
  styleUrls: ['./form-mikumite.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormMikumiteComponent), multi: true },
  ]
})

export class FormMikumiteComponent implements ControlValueAccessor, OnInit {
  @Input() form: NgForm;
  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() set filter(value: FormMikumiteFilter) {
    if (value !== this._filter) {
      this._filter = value;
    }
  }

  get filter(): FormMikumiteFilter {
    if (!this._filter) {
      this.filter = new FormMikumiteFilter();
    }
    return this._filter;
  }

  @Output('change') changeEvent = new EventEmitter();

  _filter: FormMikumiteFilter;
  _errorMessage: string;
  _componenteInicializado: boolean;
  _identifier = `form-kumite-${identifier++}`;


  constructor(private _authService: AuthService)
  {
    this.required = false;
  }

  ngOnInit(): void {
    this.findKumites();
  }

  _value: string;

  public get value(): string {
    return this._value;
  }

  public set value(v) {
    this._value = v;
    this._onChange(this._value);
    this._onTouched();
    this._mikumiteId$.next(v);
  }

  _loading = false;
  _lookupMikumitesUrl = `${AuthService.config.GatewayApi}/lookup/mikumites`;
  _mikumiteId$ = new Subject<string>();
  _mikumiteInput$ = new Subject<string>();
  _mikumites$: Observable<any[]>;
  
  findKumites() {
    this._mikumites$ = merge(
      this._mikumiteId$.pipe(
        switchMap(id => {
          this._loading = true;
          this.filter.id = id;
          return this._authService.post(this._lookupMikumitesUrl, this.filter)
            .pipe(finalize(() => { this._loading = false; }));
        })),
      this._mikumiteInput$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(termo => {
          this._loading = true;
          this.filter.id = null;
          this.filter.termo = termo;
          return this._authService.post(this._lookupMikumitesUrl, this.filter)
            .pipe(finalize(() => { this._loading = false; }));
        }))
    );
  }

  _ngModelChanged(evt) {
    this._onChange(evt);
  }

  _itemChanged(evt) {
    this.changeEvent.emit(evt);
  }

  //ControlValueAccessor Interface
  writeValue(obj: string): void {
    this._value = obj;
    this._mikumiteId$.next(obj);

  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _onChange: any = () => { };
  _onTouched: any = () => { };

}
let identifier = 0;
