import { Component, Inject, Optional, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';
import { Currency } from '../../_models/financas/currency';
import { AuthService } from '../../_services/auth.service';

const gatewayUrl = `${AuthService.config.GatewayApi}/lookup`;

@Component({
  selector: 'form-currency-group',
  templateUrl: './form-currency-group.component.html',
  styleUrls: ['./form-currency-group.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormCurrencyGroupComponent, multi: true }
  ]
})
export class FormCurrencyGroupComponent extends ElementBase<string> implements OnInit {

  public identifier = `form-currency-group-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  mask: any;

  _currency: string;
  @Input()
  get currency() {
    return this._currency;
  }
  set currency(val) {
    if (val) {
      this._currency = val;
      this.currencyChange.emit(this._currency);
    }
  }

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.resetInputValidation();
    }
  }

  _setAsTouched: boolean;
  get setAsTouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsTouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.setInputAsInvalid();
    }
  }

  @Input() currencies: Currency[];
  @Input() disabledDropdown: boolean;

  @Output() currencyChange = new EventEmitter();
  @Output() onCurrencyChanged = new EventEmitter<string>();

  constructor(
    private _authService: AuthService,
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    this.defineMascara();
    this.getCurrencies();
  }

  getCurrencies() {
    if (!this.currencies) {
      this._authService.post(`${gatewayUrl}/moedas`, { regionalId: null })
        .subscribe(result => {
          this.currencies = result;
        });
    }
  }
  
  resetInputValidation() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }

  setInputAsInvalid() {
    this.model.control.markAsDirty();
    this.model.control.markAsTouched();
  }

  defineMascara(): void {
    this.mask = {
      prefix: "",
      thousands: ".",
      decimal: ",",
      allowNegative: false,
      nullable: false
    };
  }
  selectItem(item: Currency): void {
    this.currency = item.code;
    this.onCurrencyChanged.emit(item.code);
  }
  getMoedaSimbolo() {
    if (this.currency) {
      return this.currencies ? this.currencies.find(c => c.code == this.currency).symbol : "";
    } else {
      return "";
    }
  }

}

let identifier = 0;
