import { Component, Inject, Optional, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-numeric',
  templateUrl: './form-numeric.component.html',
  styleUrls: ['./form-numeric.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormNumericComponent, multi: true }
  ]
})
export class FormNumericComponent extends ElementBase<string> implements OnInit {

  public identifier = `form-numeric-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  @Input() maxLength: any;
  @Input() max: any;
  @Input() alignLeft: boolean;
  @Output() blur = new EventEmitter();

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.resetInputValidation();
    }
  }

  _setAsTouched: boolean;
  get setAsTouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsTouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.setInputAsInvalid();
    }
  }

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  ngOnInit() { }

  resetInputValidation() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }


  setInputAsInvalid() {
    this.model.control.markAsDirty();
    this.model.control.markAsTouched();
  }


  onblur(event) {
    this.blur.emit(event.target.value);
  }

}

let identifier = 0;

