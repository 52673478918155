<li class="nav-item dropdown pe-3">
  <a class="nav-link position-relative" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    <i id="bell" class="fas fa-exclamation-triangle fa-2x text-warning" title="{{ 'LABEL_AVISOS' | translate }}"></i>
    <span class="position-absolute top-1 start-100 translate-middle badge rounded-pill bg-danger" *ngIf="avisos && avisos.total > 0">
      {{ avisos ? avisos.total : null }}
    </span>
  </a>
  <ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="navbarDropdown">
    <ng-container *ngFor="let item of avisos?.items" [ngTemplateOutlet]="AccordionAlert" [ngTemplateOutletContext]="{msg: item.mensagem, qtd: item.quantidade, url: item.url}"></ng-container>
    <!--<li><a class="dropdown-item" href="#">Action</a></li>
    <li><a class="dropdown-item" href="#">Another action</a></li>
    <li><hr class="dropdown-divider"></li>
    <li><a class="dropdown-item" href="#">Something else here</a></li>-->
  </ul>
</li>


<!--Componente de notificação-->
<ng-template #AccordionAlert let-msg="msg" let-qtd="qtd" let-url="url">
  <li>
    <a class="dropdown-item position-relative" style="width: 200px" routerLink="{{url}}">
      {{msg}} <span class="badge bg-danger position-absolute end-0 me-3">{{qtd}}</span>
    </a>
    <!--<div class="alert container-notification" role="alert" routerLink="{{url}}" style="{{ url ? 'cursor:pointer' : '' }}">
      <span class="titulo">{{msg}} </span>
      <span class="totalizador px-2 rounded">{{qtd}}</span>
    </div>-->
  </li>
</ng-template>
