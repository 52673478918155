import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { NgModel, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-year',
  templateUrl: './form-year.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormYearComponent, multi: true }
  ]
})
export class FormYearComponent extends ElementBase<Date> implements OnInit {

  public identifier = `form-year-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  yearRange: string;
  dataAtual: Date;

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.reset();
    }
  }

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {

    super(validators, asyncValidators);

    this.dataAtual = new Date();
    this.yearRange = `${this.dataAtual.getFullYear() - 20}:${this.dataAtual.getFullYear() + 20}`;
  }

  reset() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }

  ngOnInit() {
  }
}

let identifier = 0;
