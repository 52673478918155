import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { INotification } from '../_interfaces/INotification';
import { AuthService } from './auth.service';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private hubConnection: signalR.HubConnection;  private _notifications: Subject<INotification>;

  constructor(
    private _oidcSecurityService: OidcSecurityService,
    private _toastr: ToastrService) {
    this._notifications = new Subject<INotification>();
  }

  public initializeHubConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${AuthService.config.GatewayApi}/notify`, {
        //headers: {
        //  "Authorization": `Bearer ${this._authService.getUsuarioToken()}`
        //}
        accessTokenFactory: () => this._oidcSecurityService.getAccessToken().toPromise()
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          if (retryContext.elapsedMilliseconds < 60000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait 5 seconds before the next reconnect attempt.
            return 5000;
          } else {
            // If we've been reconnecting for more than 60 seconds so far, wait 30 seconds.
            return 30000;
          }
        }
      })
      .build();

    this.hubConnection
      .on("ReceiveMessage", (message: INotification) => {
        this._notifications.next(message);
      });

    this.hubConnection.onclose(async () => {
      await this.startConnection();
    });
  }

  public async startConnection() {
      await this.initializeHubConnection();
      this.hubConnection
      .start()
      .then(() => console.log('Conexão com o Hub de notificação estabilizada'))
      .catch(err =>
      {
        console.log('Erro ao estabilizar a conexão com o Hub de notificação: ' + err);
        setTimeout(this.startConnection, 5000);
      });
  }

  getNotification(): Observable<INotification> {
    return this._notifications.asObservable();
  }

  success(message?: string, title?: string) {
    return this._toastr.success(message, title, { enableHtml: true });
  }

  warning(message?: string, title?: string) {
    return this._toastr.warning(message, title, { enableHtml: true });
  }

  error(message?: string, title?: string) {    
    return this._toastr.error(message, title, { enableHtml: true });
  }
}
