interface Date {
    getUTCISOString(datePart: string): string;
  }


Date.prototype.getUTCISOString = function (datePart: string = 'DateTime') {
  const year = this.getFullYear().toString();
  const month = (this.getMonth() + 1).toString();
  const day = datePart === "DateOnly" || datePart === "DateHourMinute" || datePart === "DateTime" ? this.getDate().toString() : '01';
  const hour = datePart === "DateHourMinute" || datePart === "DateTime" ? this.getHours().toString() : '00';
  const minutes = datePart === "DateHourMinute" || datePart === "DateTime" ? this.getMinutes().toString() : '00';
  const seconds = datePart === "DateTime" ? this.getSeconds().toString() : '00';

  return `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T` +
    `${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
};
