      <user-menu></user-menu>

<!--<header class="blog-header py-3 container-fluid" style="background-color: #fff">
  <div class="row">
    <div class="col-4">
      <img src="../../assets/img/logo_jp.png" />
    </div>
    <div class="col-4 d-flex justify-content-center">
      <img class="cursor-pointer" src="../../assets/img/logo_goshinmon_en.png" [routerLink]="['/home']" alt="{{ 'TITULO_SISTEMAMAHIKARI' | translate }}" />
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <accordion style="position: absolute; transform: translate(-103px, 0px); z-index: 5;"></accordion>
      <notification #notificationComponent style="position: absolute;transform: translate(-40px, 0px);z-index: 5;"></notification>
    </div>
  </div>
</header>-->
