import { Component, Inject, Optional, OnInit, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

import isValidBoleto from '@brazilian-utils/is-valid-boleto';

@Component({
  selector: 'form-linha-digitavel',
  templateUrl: './form-linha-digitavel.component.html',
  styleUrls: ['./form-linha-digitavel.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormLinhaDigitavelComponent, multi: true }
  ]
})
export class FormLinhaDigitavelComponent extends ElementBase<string> implements OnInit {

  public identifier = `form-linha-digitavel-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }
  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.resetInputValidation();
    }
  }

  @Input() public mask: string;
  codigoBarrasInvalido: boolean;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    this.codigoBarrasInvalido = false;
  }

  resetInputValidation() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }

  verificarCodigoBarras(codigoBarras: string) {
    this.codigoBarrasInvalido = !(isValidBoleto(codigoBarras));
  }

}

let identifier = 0;
