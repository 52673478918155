<div class="mb-3">
  <div class="form-check" [ngClass]="switch ? 'form-switch' : null" style="font-size: 16px">
    <input class="form-check-input" type="checkbox" [id]="identifier" [(ngModel)]="value" [disabled]="disabled">
    <label class="form-check-label" [for]="identifier" style="font-size:12px">{{ label }}</label>
  </div>
</div>




