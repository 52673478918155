import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule } from 'ngx-mask';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { CnpjDirective } from '../_directives/cnpj.directive';
import { ValidationMessages } from './base/validation-messages.component';
import { CardComponent } from './card/card.component';

import { FormAreaComponent } from './form-area/form-area.component';
import { FormButtonComponent } from './form-button/form-button.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormCurrencyGroupComponent } from './form-currency-group/form-currency-group.component';
import { FormCurrencyComponent } from './form-currency/form-currency.component';
import { FormDateComponent } from './form-date/form-date.component';
import { FormKumiteComponent } from './form-kumite/form-kumite.component';
import { FormLinhaDigitavelComponent } from './form-linha-digitavel/form-linha-digitavel.component';
import { FormMaskAutocompleteComponent } from './form-mask-autocomplete/form-mask-autocomplete.component';
import { FormMaskComponent } from './form-mask/form-mask.component';
import { FormMikumiteComponent } from './form-mikumite/form-mikumite.component';
import { FormMonthComponent } from './form-month/form-month.component';
import { FormYearComponent } from './form-year/form-year.component';
import { FormNumericComponent } from './form-numeric/form-numeric.component';
import { FormPaginatorComponent } from './form-paginator/form-paginator.component';
import { FormRadioComponent } from './form-radio/form-radio.component';
import { FormRegionalComponent } from './form-regional/form-regional.component';
import { FormListRegionalComponent } from './form-list-regional/form-list-regional.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormTextComponent } from './form-text/form-text.component';
import { FormTimeComponent } from './form-time/form-time.component';
import { FormUploadComponent } from './form-upload/form-upload.component';
import { FormValidationComponent } from './form-validation/form-validation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { VincularPaisesComponent } from './vincular-paises/vincular-paises.component';
import { ValidationErrorsComponent } from './base/validation-errors/validation-errors.component';






@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgxMaskModule.forChild(),
    NgxCurrencyModule,
    CalendarModule,
    PaginatorModule,
    TooltipModule,
    FileUploadModule,
    TableModule,
    NgSelectModule
  ],
  declarations: [
    ValidationMessages,
    FormTextComponent,
    FormDateComponent,
    SpinnerComponent,
    FormCheckboxComponent,
    CardComponent,
    FormMaskAutocompleteComponent,
    CnpjDirective,
    FormMaskComponent,
    FormCurrencyComponent,
    FormNumericComponent,
    FormMonthComponent,
    FormYearComponent,
    FormCurrencyGroupComponent,
    FormLinhaDigitavelComponent,
    FormUploadComponent,
    FormAreaComponent,
    FormRadioComponent,
    FormValidationComponent,
    FormKumiteComponent,
    FormMikumiteComponent,
    FormRegionalComponent,
    FormListRegionalComponent,
    FormSelectComponent,
    FormTimeComponent,
    FormPaginatorComponent,
    FormButtonComponent,
    VincularPaisesComponent,
    ValidationErrorsComponent
  ],
  exports: [
    FormsModule,
    StepsModule,
    TabViewModule,
    PaginatorModule,
    DropdownModule,
    TooltipModule,
    TableModule,
    NgSelectModule,
    ValidationMessages,
    FormTextComponent,
    FormDateComponent,
    SpinnerComponent,
    FormCheckboxComponent,
    CardComponent,
    FormMaskAutocompleteComponent,
    FormMaskComponent,
    CnpjDirective,
    FormCurrencyComponent,
    FormNumericComponent,
    FormMonthComponent,
    FormYearComponent,
    FormCurrencyGroupComponent,
    FormLinhaDigitavelComponent,
    FormUploadComponent,
    FormAreaComponent,
    FormRadioComponent,
    FormValidationComponent,
    FormKumiteComponent,
    FormMikumiteComponent,
    FormRegionalComponent,
    FormListRegionalComponent,
    FormSelectComponent,
    FormTimeComponent,
    FormPaginatorComponent,
    FormButtonComponent,
    VincularPaisesComponent,
    ValidationErrorsComponent
  ],
  providers: [
    TranslateService
  ]
})
export class FormModule { }
