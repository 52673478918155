<li *ngFor="let item of items; let idx = index"
    class="subMenuClass"
    [ngClass]="[item.subItens.length > 0 ? 'dropdown-submenu' : '']">

  <a *ngIf="item.subItens.length > 0"
     class="dropdown-item dropdown-toggle"  style="padding-right:20px"
     href="javascript:void(0)">{{ item.nome | translate }}</a>

  <a *ngIf="item.subItens.length == 0"
     class="dropdown-item" style="padding-right:20px"
     href="#"
     routerLink="{{item.routerLink}}">{{ item.nome | translate }}</a>

  <sub-item-menu *ngIf="item.subItens.length > 0"
                 [items]="item.subItens"
                 class="dropdown-menu"></sub-item-menu>
</li>
