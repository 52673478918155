import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[cnpj][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CnpjDirective, multi: true }
  ]
})
export class CnpjDirective {
  validate(control: AbstractControl): { [validator: string]: string } {

    if (!control.value) {
      return null;
    }

    const cnpjRegex = /^[0-9]{2,3}\.?([0-9]{3}\.?){2}\/[0-9]{4}-[0-9]{2}$/;
    const value = control.value.trim();

    if (value.length < 18) {
      return null;
    }

    // Validar a estrutura do cnpj
    if (cnpjRegex.test(value)) {

      // Validar os dígitos verificadores
      let sum: number = 0;
      let digit1: number = 0;
      let digit2: number = 0;

      let localCnpj: string = value.replace(/\D/g, '');

      if (localCnpj.length == 14) {

        //Digit 1
        sum = 0;
        for (let i = 0; i <= 11; i++) {
          if (i < 4) {
            sum += +localCnpj.substring(i, i + 1) * (5 - i);
          }
          else {
            sum += +localCnpj.substring(i, i + 1) * (13 - i);
          }
        }

        digit1 = 11 - (sum % 11);

        if (digit1 > 9) {
          digit1 = 0;
        }

        //Dígit 2
        sum = 0;

        for (let i = 0; i <= 12; i++) {
          if (i < 5) {
            sum += +localCnpj.substring(i, i + 1) * (6 - i);
          }
          else {
            sum += +localCnpj.substring(i, i + 1) * (14 - i);
          }
        }

        digit2 = 11 - (sum % 11);

        if (digit2 > 9) {
          digit2 = 0;
        }

        //Check digits
        if (digit1 == +localCnpj.substring(12, 13) && digit2 == +localCnpj.substring(13, 14)) {
          return null;
        }
      }
    }

    return { cnpj: 'Informe um cnpj válido' };
  }
}
