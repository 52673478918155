<div class="ui-fluid mb-3">
  <label [ngClass]="{'required': required}" *ngIf="label">{{ label }}</label><br />
  <ng-select [id]="_identifier" [(ngModel)]="_value" (ngModelChange)="_ngModelChanged($event)" (change)="_itemChanged($event)"
             bindLabel="nomeExibicao" bindValue="id" #model="ngModel"
             [items]="_mikumites$ | async" [typeahead]="_mikumiteInput$" [hideSelected]="true"
             [ngClass]="{ 'is-invalid': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors}"
             typeToSearchText="{{ 'MENSAGEM_DIGITEPARABUSCAR' | translate }}"
             notFoundText="{{ 'MENSAGEM_NAOENCONTRADO' | translate }}" [disabled]="disabled"
             [required]="required" [loading]="_loading">
  </ng-select>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
