import { Component, OnInit } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { I18nService } from '../../../_i18n/i18n.service';
import { AuthService } from '../../../_services/auth.service';
import Modal from 'bootstrap/js/dist/modal';
import { OidcSecurityService } from 'angular-auth-oidc-client';

declare const $: any;

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {

  userPicture = null;
  fotoFile: any = {};
  paises = [];
  paisSelecionado: any = {};
  usuario;
  usuarioNomeIniciais;
  loadingFotoPerfil: boolean = true;
  cultures = [
    { id: 'pt-BR', nome: 'Português (BR)', icone: '../../assets/img/flags/BR.png' },
    { id: 'ja-JP', nome: '日本語', icone: '../../assets/img/flags/JP.png' },
    { id: 'en-US', nome: 'English', icone: '../../assets/img/flags/US.png' },
    { id: 'es-MX', nome: 'Español (MX)', icone: '../../assets/img/flags/MX.png' },
    { id: 'es-PE', nome: 'Español (PE)', icone: '../../assets/img/flags/PE.png' }
  ];
  dataAtual: Date;

  constructor(
    private _oidcSecurityService: OidcSecurityService,
    private _authService: AuthService,
    private _i18nService: I18nService) {
    this.dataAtual = new Date();
    this.getMensagemSaudacao();
  }

  async ngOnInit() {

    await this._authService.get(`${AuthService.config.GatewayApi}/login`)
      .subscribe(user => {
        const firstName = user.nome;
        const lastName = user.sobrenome;
        this.usuarioNomeIniciais = lastName.charAt(0) + firstName.charAt(0);
        this.usuario = user;
      });

    await this._authService.get(`${AuthService.config.GatewayApi}/login/getpaises`)
      .subscribe(paises => {
        this.paises = paises;
        this.paisSelecionado.id = localStorage.getItem("PaisId");

        if (this.paises.length == 1) {
          localStorage.setItem('PaisId', this.paises[0].id);
        }

        this.validaPaisSelecionado();

        this.setPaisNome();
      });

    this.getProfileImage();

    $('#profileDialog').on('click', function (e) {
      e.stopPropagation();
    });
  }

  salvarPaisSelecionado() {
    localStorage.setItem('PaisId', this.paisSelecionado.id);
  }

  validaPaisSelecionado() {
    if (localStorage.getItem("PaisId") == null) {
      var modal = new Modal(document.getElementById('modalSelecionaPais'));
      modal.show();
    }
  }

  setTranslation(culture: string): void {
    this._i18nService.setTranslation(culture);
    window.location.reload();
  }

  getCurrentCulture(): any {
    return this.cultures.find(c => c.id == this._i18nService.getCulture());
  }

  getMensagemSaudacao(): string {
    const horaAtual = this.dataAtual.getHours();
    if (horaAtual >= 5 && horaAtual < 12) return 'LABEL_BOMDIA';
    else if (horaAtual >= 12 && horaAtual <= 18) return 'LABEL_BOATARDE';
    else return 'LABEL_BOANOITE'
  }

  setPaisNome() {
    for (var i = 0; i < this.paises.length; i++) {
      if (this.paises[i].id == this.paisSelecionado.id) {
        this.paisSelecionado.nome = this.paises[i].nome;
      }
    }
  }

  paisChanged(paisId = null) {
    if (paisId != null) {
      this.paisSelecionado.id = paisId;
      this.setPaisNome();
    }

    localStorage.setItem('PaisId', this.paisSelecionado.id);
    window.location.reload();
  }

  
  onChangeFile() {

    const file = (<any>document.querySelector('input[type=file]')).files[0];
    const reader = new FileReader();

    reader.addEventListener("load", this.setImage.bind(reader), false);

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  setImage(reader: FileReader) {
    var base64 = reader.result;
    this.profileImage = base64;
  }


  //Img edit
  imageChangedEvent: any = '';
  profileImage: any = '';
  file: any = {};
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  fileChangeEvent(event: any): void {
    this.file = (<any>document.querySelector('input[type=file]')).files[0];
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.profileImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {
    console.log('Falha ao carregar imagem');
  }

  alterarFoto() {
    this.userPicture = this.profileImage;
    let model: any = {};

    model.nome = this.file.name;
    model.contentType = this.file.type;
    model.base64 = this.userPicture.replace('data:image/png;base64,', '');

    this._authService.post(`${AuthService.config.GatewayApi}/login/AlterarFoto`, model)
      .subscribe(result => {
      });
  }

  getProfileImage() {
    this._authService.post(`${AuthService.config.GatewayApi}/login/GetFotoPerfil`, {})
      .subscribe(result => {
        if (result.base64.length > 0) {
          this.userPicture = 'data:image/png;base64,' + result.base64;
        }
        this.loadingFotoPerfil = false;
      });
  }

  onImageBreak() {
    this.userPicture = null;
  }

  removerFoto() {
    this.userPicture = '';
    this.profileImage = '';

    this._authService.post(`${AuthService.config.GatewayApi}/login/ApagarFotoPerfil`, {}).subscribe();
  }

  logoff() {
    this._oidcSecurityService.logoffAndRevokeTokens().subscribe(result => console.log(result));
  }
}
