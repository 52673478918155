import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgForm, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'form-date',
  templateUrl: './form-date.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormDateComponent, multi: true }
  ]
})
export class FormDateComponent implements ControlValueAccessor, OnInit {
  public identifier = `form-date-${identifier++}`;

  _daterValue: Date;

  @ViewChild(NgModel, { static: true }) model: NgModel;
  @Input() form: NgForm;
  @Input() min: Date;
  @Input() max: Date;
  @Input() showToday: boolean = true;
  @Input() showOnTop: boolean;
  @Input() showTime: boolean;
  @Input() showSeconds: boolean;
  @Input() label: string;
  @Input() name: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Output() public change = new EventEmitter();

  _setAsUntouched: boolean;
  get setAsUntouched() {
    return this._setAsUntouched;
  }

  @Input() set setAsUntouched(value) {
    this._setAsUntouched = value;
    if (value) {
      this.resetInputValidation();
    }
  }

  constructor() {
  }

  resetInputValidation() {
    this.model.control.markAsPristine();
    this.model.control.markAsUntouched();
  }

  _dateValue: Date;

  onModelChange(value: Date) {
    this._onChange(this.getUTCISOString(value));
  }

  ngOnInit() {
  }

  getUTCISOString(date: Date): string {
    if (date) {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString();
      const day = date.getDate().toString();
      const hour = this.showTime ? date.getHours().toString() : '00';
      const minutes = this.showTime ? date.getMinutes().toString() : '00';
      const seconds = this.showTime && this.showSeconds ? date.getSeconds().toString() : '00';

      return `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T` +
        `${hour.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    }
    else {
      return null;
    }
  }

  getDateFromString(str: string): Date {
    if (!str)
      return null;

    let date = new Date(str);
    return Number.isNaN(date) ? null : date;
  }

  //ControlValueAccessor Interface
  writeValue(obj: string): void {
    this._dateValue = this.getDateFromString(obj);
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _onChange: any = () => { };
  _onTouched: any = () => { };
}

let identifier = 0;
