import { Component, Inject, Optional, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-mask-autocomplete',
  templateUrl: './form-mask-autocomplete.component.html',
  styleUrls: ['./form-mask-autocomplete.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormMaskAutocompleteComponent, multi: true }
  ]
})
export class FormMaskAutocompleteComponent extends ElementBase<string> implements OnInit {

  public identifier = `form-mask-autocomplete-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  @Input() public mask: string;
  @Output() public blur = new EventEmitter();
  alterado: boolean = false;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

  ngOnInit() { }

  onBlur(): void {
    if (this.alterado) {
      this.alterado = false;
      if (this.value)
        this.addZeroEsquerda(this.value, this.mask.length);
      this.blur.emit(true);
    }
  }
  changeControlState(): void {
    this.alterado = true;
  }
  addZeroEsquerda(valor: string, quantidade: number): void {
    let nZeros = '';
    let n = '';
    for (let i = 0; i < quantidade; i++) {
      nZeros += '0';
      if (valor[i] != undefined) n += valor[i];
    }
    this.value = (nZeros + n).slice(-quantidade);
  }

}

let identifier = 0;
