import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../../_services/auth.service';
import { MessageService } from '../../../../_services/message.service';
import { NotificationService } from '../../../../_services/notification.service';

@Component({
  selector: 'app-kumite-aceite',
  templateUrl: './kumite-aceite.component.html'
})
export class KumiteAceiteComponent implements OnInit, OnDestroy {

  model: any = {};
  salvando: boolean;
  activeIndex: number;
  buscando: boolean;
  btnText: string;

  constructor(
    private _router: ActivatedRoute,
    private _route: Router,
    private _authService: AuthService,
    private _notificationService: NotificationService
  ) { }

  ngOnInit() {
    this._router.params.subscribe(params => {
      this.getKumite(params['hash']);
    });

    this.btnText = 'SIM, dou o meu consentimento';
  }
  getKumite(hash: string) {

    this._authService.get(`${AuthService.config.GatewayApi}/kumite/aceite/${hash}`)
      .pipe()
      .subscribe(result => {
        this.model = result;
        this.model.hash = hash;
      }, () => this._route.navigate(['/pessoa/kumite/aceite']));
  }

  ngOnDestroy(): void {
    this._notifySubscription.unsubscribe();
  }

  find() {
  }

  aceitar(hash: string) {
    this._authService.post(`${AuthService.config.GatewayApi}/kumite/aceite/`, { hash: hash })
      .subscribe();

    setTimeout(() => this.btnText = 'Obrigado!', 2000);
  }

  _notifySubscription: Subscription;
  notificationSubscription() {
    this._notifySubscription = this._notificationService.getNotification()
      .subscribe(msg => {
        if (msg.sucesso) return;
      });
  }
}
