import { Directive } from '@angular/core';

import {
  NG_VALIDATORS,
  AbstractControl,
  Validator,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';

@Directive({
  selector: '[japanese]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: JapaneseValueValidator, multi: true }
  ]
})
export class JapaneseValueValidator implements Validator {


  validate(control: AbstractControl): ValidationErrors | null {
    const expression = /^[\u3000-\uFF20\uFF5B-\uFFE5\d\s]*$/i;
    if (!control.value) {
      return null;
    }

    const value = control.value.trim();
    if (expression.test(value)) {
      return null;
    }

    // Example of how to do asynchronous validation
    //return Observable.create(obs => {
    //  setImmediate(() => {
    //    obs.next({ jpPattern: 'MENSAGEM_CAMPOJAPONESINVALIDO' });
    //    obs.complete();
    //  });
    //});
    return { jpPattern: true }
  }
}

