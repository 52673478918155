import { FlagAcesso } from '../../_models/autorizacao/flag-acesso';
import { GrauRegional } from '../../_models/enum/grau-regional';

export class FormRegionalFilter {
  termo?: string;
  id?: string;
  inativo? = false;
  regionalIds?: string[];
  grausRegional?: GrauRegional[];
  acesso?: FlagAcesso = FlagAcesso.Ler;
  paisId?: string;
  todosPaises?: boolean = false;
  incluirExterior?: boolean = false;
  incluirExteriorMikumite?: boolean = false;
  incluirSedeAL?: boolean = false;
}

