import { Component, OnInit } from '@angular/core';

import { NotificationService } from '../../_services/notification.service';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html'
})
export class SecureComponent implements OnInit {

  constructor(private _notificationService: NotificationService) { }

  ngOnInit() {
    this._notificationService.startConnection();
  }
}

