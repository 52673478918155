import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgForm, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AuthService } from '../../_services/auth.service';
import { FormListRegionalFilter } from './form-list-regional.models';

@Component({
  selector: 'form-list-regional',
  templateUrl: './form-list-regional.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormListRegionalComponent), multi: true },
  ]
})

export class FormListRegionalComponent implements ControlValueAccessor, OnInit {
  @Input() form: NgForm;
  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() set filter(value: FormListRegionalFilter) {
    if (value !== this._filter) {
      this._filter = value;
    }
  }

  get filter(): FormListRegionalFilter {
    if (!this._filter) {
      this.filter = new FormListRegionalFilter();
    }
    return this._filter;
  }

  @Output('change') changeEvent = new EventEmitter();


  _filter: FormListRegionalFilter;
  _errorMessage: string;
  _componenteInicializado: boolean;
  _identifier = `form-list-regional-${identifier++}`;


  constructor(private _authService: AuthService) {
    this.required = false;
  }

  ngOnInit(): void {
    this.initializeSubjects();
  }

  _value: string;

  public get value(): string {
    return this._value;
  }

  public set value(v) {
    this._value = v;
    this._onChange(this._value);
    this._onTouched();
    this._regionalId$.next(v);
  }

  _loading = false;
  _lookupRegionaisUrl = `${AuthService.config.GatewayApi}/lookup/regionais`;
  _regionalId$ = new Subject<string>();
  _regionalInput$ = new Subject<string>();
  _regionais$ = new Subject<[]>();

  //@ViewChild("model") model: NgModel;
  initializeSubjects() {
    this._regionalId$.subscribe(id => {
      this._loading = true;
      this.filter.id = id;
      this._authService.post(this._lookupRegionaisUrl, this.filter)
        .pipe(finalize(() => { this._loading = false; }))
        .subscribe(result => {
          this._regionais$.next(result);

          //if (result.length > 0 && (id == null || id == undefined)) {
          //  let regionalId = result[0].id;
          //  this.model.control.setValue(regionalId);
          //}
        });
    });

    this._regionalInput$.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(termo => {
        this._loading = true;
        this.filter.id = null;
        this.filter.termo = termo;
        this._authService.post(this._lookupRegionaisUrl, this.filter)
          .pipe(finalize(() => { this._loading = false; }))
          .subscribe(result => { this._regionais$.next(result); });
      });
  }

  _ngModelChanged(evt) {
    this._onChange(evt);
  }

  _itemChanged(evt) {
    this.changeEvent.emit(evt);
  }

  //ControlValueAccessor Interface
  writeValue(obj: string): void {
    this._value = obj;
    this._regionalId$.next(obj);
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  _onChange: any = () => { };
  _onTouched: any = () => { };

}
let identifier = 0;
