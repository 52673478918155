import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_services/auth.service';
import { I18nService } from '../../../_i18n/i18n.service';

@Component({
  selector: 'nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.css']
})
export class NavHeaderComponent implements OnInit {

  paises = [];
  paisSelecionado = null;
  usuario;
  cultures = [
    { id: 'pt-BR', nome: 'Português (BR)', icone: '../../assets/img/flags/BR.png' },
    { id: 'ja-JP', nome: '日本語', icone: '../../assets/img/flags/JP.png' },
    { id: 'en-US', nome: 'English', icone: '../../assets/img/flags/US.png' },
    { id: 'es-MX', nome: 'Español (MX)', icone: '../../assets/img/flags/MX.png' },
    { id: 'es-PE', nome: 'Español (PE)', icone: '../../assets/img/flags/PE.png' }
  ];
  dataAtual: Date;

  constructor(private _authService: AuthService,
    private _i18nService: I18nService) {
    this.dataAtual = new Date();
    this.getMensagemSaudacao();
  }

  async ngOnInit() {
    await this._authService.get(`${AuthService.config.GatewayApi}/login`)
      .subscribe(usuario => {
        this.usuario = usuario;
      });
    await this._authService.get(`${AuthService.config.GatewayApi}/login/getpaises`)
      .subscribe(paises => {
        this.paises = paises;
        this.paisSelecionado = localStorage.getItem("PaisId");
      });
  }

  setTranslation(culture: string): void {
    this._i18nService.setTranslation(culture);
  }

  getCurrentCulture(): any {
    return this.cultures.find(c => c.id == this._i18nService.getCulture());
  }

  getMensagemSaudacao(): string {
    const horaAtual = this.dataAtual.getHours();
    if (horaAtual >= 5 && horaAtual < 12) return 'LABEL_BOMDIA';
    else if (horaAtual >= 12 && horaAtual <= 18) return 'LABEL_BOATARDE';
    else return 'LABEL_BOANOITE'
  }

  paisChanged() {
    localStorage.setItem('PaisId', this.paisSelecionado);
  }
}
