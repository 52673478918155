
export class FormMikumiteFilter {
  termo: string;
  id: string;
  regionalIds: string[];
  dataInicial: Date;
  dataFinal: Date;
  inativo = false;
  convertido: boolean;
  aplicarVisibilidade = true;
}

