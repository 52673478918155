import { Injectable } from '@angular/core';

import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  success(title: string, text?: string): Promise<SweetAlertResult> {
    return (
      Swal.fire({
        icon: 'success',
        title: title,
        text: text
      }).then(value => value));
  }
  info(title: string, text?: string): Promise<SweetAlertResult> {
    return (
      Swal.fire({
        icon: 'info',
        title: title,
        text: text
      }).then(value => value));
  }
  warning(title: string, text?: string): Promise<SweetAlertResult> {
    return (
      Swal.fire({
        icon: 'warning',
        title: title,
        text: text
      }).then(value => value));
  }
  htmlWarning(title: string, text?: string): Promise<SweetAlertResult> {
    return (
      Swal.fire({
        icon: 'warning',
        title: title,
        html: text
      }).then(value => value));
  }
  error(title: string, text?: string, timer?: number, allowConfirm?: boolean): Promise<SweetAlertResult> {
    return (
      Swal.fire({
        icon: 'error',
        title: title,
        text: text,
        timer: timer,
        showConfirmButton: allowConfirm == null ? true : allowConfirm,
        allowOutsideClick: allowConfirm == null ? true : allowConfirm
      }).then(value => value));
  }
  confirm(title: string, text: string, confirmText: string, cancelText: string, allowOutsideClick: boolean = true): Promise<SweetAlertResult> {
    return (
      Swal.fire({
        icon: 'question',
        title: title,
        text: text,
        showCancelButton: true,
        cancelButtonText: cancelText,
        cancelButtonColor: '#d33',
        showConfirmButton: true,
        confirmButtonText: confirmText,
        confirmButtonColor: '#3085d6',
        allowOutsideClick: allowOutsideClick
      }).then(value => value));
  }
  deleteConfirm(title: string, text: string, confirmText: string, cancelText: string): Promise<SweetAlertResult> {
    return (
      Swal.fire({
        icon: 'question',
        title: title,
        text: text,
        showCancelButton: true,
        cancelButtonText: cancelText,
        showConfirmButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: confirmText
      }).then(value => value));
  }

}
