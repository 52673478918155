import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeJa from '@angular/common/locales/ja';
import localeEsMX from '@angular/common/locales/es-MX';
import localeEsPE from '@angular/common/locales/es-PE';

import { TranslateLoader, TranslateService } from '@ngx-translate/core';

import { AuthService } from '../_services/auth.service';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class I18nService implements TranslateLoader {

  constructor(private _authService: AuthService,
    private _primeNGConfig: PrimeNGConfig,
    private _translateService: TranslateService) { }

  getCulture(): string {
    let culture = JSON.parse(localStorage.getItem('Culture'));
    if (!culture) {
      culture = this._translateService.getBrowserCultureLang();
      this.setCulture(culture);
    }
    return culture;
  }

  setCulture(culture: string): void {
    localStorage.setItem('Culture', JSON.stringify(culture));
  }

  getTranslation(): Observable<any> {
    return this._authService.get(`${AuthService.config.GatewayApi}/localization`);
  }

  setTranslation(culture: string): void {
    if (this.getCulture() !== culture) this.setCulture(culture);

    let lang = this._translateService.langs.find(c => c == culture);
    if (!lang) {
      this._translateService.getTranslation(culture)
        .subscribe(() => {
          this._translateService.use(culture);
        });
    } else {
      this._translateService.use(culture);
    }

    this._authService.get(`assets/i18n/prime-date/${culture}.json`)
      .subscribe(res => {
        this._primeNGConfig.setTranslation(res)
      });

    this.registerLocaleDate();
  }

  registerLocaleDate(): void {
    switch (this.getCulture()) {
      case 'pt-BR':
        registerLocaleData(localePt);
        break;
      case 'en-US':
        registerLocaleData(localeEn);
        break;
      case 'ja-JP':
        registerLocaleData(localeJa);
        break;
      case 'es-MX':
        registerLocaleData(localeEsMX);
        break;
      case 'es-PE':
        registerLocaleData(localeEsPE);
        break;
      default:
        registerLocaleData(localePt);
        break;
    }
  }
}
