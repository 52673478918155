import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'form-validation',
  templateUrl: './form-validation.component.html'
})
export class FormValidationComponent implements OnInit {
  constructor(private _translateService: TranslateService) {
  }

  ngOnInit():void {
  }

  @Input() titulo: string;
  @Input() erros: string[];
}
