<div class="col-md-4 mx-auto">
  <div class="card border; card-opacity">
    <div class="card-body">
      <h4 style="text-align: center; color: white; text-shadow: 2px 2px 1px #000;">{{ 'LABEL_SELECIONEPAIS' | translate }}</h4>
      <form name="form" (ngSubmit)="selecionar(form)"  #form="ngForm" novalidate>
        <div class="mb-3">
            <ng-select name="paisId" [(ngModel)]="paisId" [clearable]="false">
              <ng-option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</ng-option>
            </ng-select>
        </div>
        <div class="mb-3">
          <button [disabled]="!paisId" class="btn btn-primary pull-right">
            <span>{{ 'BOTAO_OK' | translate }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


