import { Component, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';

//import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormButtonComponent, multi: true }
  ]
})
export class FormButtonComponent {

  @HostBinding('style.pointer-events') get pEvents(): string {
    if (this.disabled) {
      return 'none';
    }
    return 'auto';
  }

  public identifier = `form-button-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;

  @Input() label: string;
  @Input() iconClass: string;
  @Input() customClass: string;
  @Input() customType: string = "button";
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() iconRight: boolean;

  constructor() { }

}

let identifier = 0;
