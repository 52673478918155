
<nav-header></nav-header>
<nav-menu></nav-menu>
<div class="d-flex justify-content-center">
  <div class="card border" style="width:100%">
    <div class="card-body card-layout">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

