<div class="mb-3">
  <label *ngIf="label" [ngClass]="{'required': required}" [for]="identifier" style="inline-size: max-content">{{ label }}</label>
  <input [id]="identifier" type="text" class="form-control"
         name="identifier" [(ngModel)]="value"
         [maxlength]="maxlength ? maxlength : null" [minlength]="minlength ? minlength : null"
         [disabled]="disabled" [readonly]="disabled" [required]="required"
         [ngClass]="{ 'is-invalid': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors}" style="font-size:15px;height:36px" />
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>

