import { Directive, Input } from '@angular/core';
import { UntypedFormGroup, NgForm, NgModel } from '@angular/forms';
import {
    AsyncValidatorArray, ValidatorArray
} from './validate';
import { ValueAccessorBase } from './value-accessor-base';


@Directive()
export abstract class ElementBase<T> extends ValueAccessorBase<T> {

  protected abstract model: NgModel;
  @Input() form: NgForm;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() autofocus: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() inputLarge: boolean;
  @Input() name: string;

  constructor(
    private validators: ValidatorArray,
    private asyncValidators: AsyncValidatorArray,
  ) {
    super();
  }

  //protected validate(): Observable<ValidationResult> {
  //  return validate
  //    (this.validators, this.asyncValidators)
  //    (this.model.control);
  //}

  //get invalid(): Observable<boolean> {
  //  return this.validate().pipe(map(v => Object.keys(v || {}).length > 0));
  //}

  //protected get failures(): Observable<Array<string>> {
  //  return this.validate().pipe(map(v => Object.keys(v).map(k => message(v, k))));
  //}
}
