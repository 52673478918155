<nav *ngIf="true" class="navbar navbar-expand-lg navbar-dark fixed-top navbar-default custom nav-menu" style="z-index: 4">
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">

      <ngx-skeleton-loader count="8"
                           *ngIf="buscando"
                           [theme]="{'width':'100px',
                                     'margin-left': '15px',
                                     'margin-top': '6px',
                                     'margin-bottom': '0px',
                                     'border-radius': '0',
                                     'background-color': '#1673b9',
                                     'height': '20px'}"></ngx-skeleton-loader>

    <ul class="navbar-nav me-auto" *ngIf="!buscando">
      <li *ngFor="let item of itensMenu; let idx = index" class="subMenuClass" [ngClass]="[item.subItens.length > 0 ? 'nav-item dropdown' : 'nav-item']" routerLinkActive="active">
        <a *ngIf="item.subItens.length == 0"
           href="#" class="nav-link pe-3"
           routerLink="{{item.routerLink}}"><i class="{{ item.icon }} me-2"></i>{{ item.nome | translate }}</a>

        <a *ngIf="item.subItens.length > 0"
           href="javascript:void(0)" style="padding-right:20px"
           class="nav-link dropdown-toggle pe-3"><i class="{{ item.icon }} me-2"></i>{{ item.nome | translate }}</a>

        <sub-item-menu *ngIf="item.subItens.length > 0"
                       [items]="item.subItens"
                       class="dropdown-menu"></sub-item-menu>
      </li>
    </ul>
  </div>
</nav>
