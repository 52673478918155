import { Component, Inject, Optional, ViewChild, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';

import { ElementBase } from '../base/element-base';

@Component({
  selector: 'form-radio',
  templateUrl: './form-radio.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FormRadioComponent, multi: true }
  ]
})
export class FormRadioComponent extends ElementBase<boolean> {

  public identifier = `form-radio-${identifier++}`;

  @ViewChild(NgModel, { static: true }) model: NgModel;
  @Input() public controlValue: string;
  @Input() public checkedRadio?: boolean;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>) {
    super(validators, asyncValidators);
  }

}

let identifier = 0;
