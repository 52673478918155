<div class="ui-fluid mb-3">
  <label [ngClass]="{'required': required}" *ngIf="label">{{ label }}</label><br />
  <ng-select [id]="_identifier" [(ngModel)]="_value"
             bindLabel="nome" bindValue="id" #model="ngModel" (ngModelChange)="_ngModelChanged($event)" (change)="_itemChanged($event)"
             [items]="_regionais$ | async" [typeahead]="_regionalInput$" [hideSelected]="true"
             typeToSearchText="{{ 'MENSAGEM_DIGITEPARABUSCAR' | translate }}"
             [placeholder]="placeholder"
             notFoundText="{{ 'MENSAGEM_NAOENCONTRADO' | translate }}" [disabled]="disabled"
             [ngClass]="{ 'is-invalid': form && (form.submitted || form.controls[name]?.touched || form.controls[name]?.dirty) && form.controls[name]?.errors}"
             [required]="required" [loading]="_loading">
    <ng-template ng-option-tmp let-item="item">
      <div>{{ item.nome }}&nbsp;-&nbsp;{{ item.grau }}</div>
      <small>{{ item.nomeJapones }}</small>
    </ng-template>
    <ng-template ng-footer-tmp>
      <small style="color: #007ad9">{{ 'LABEL_RODAPE' | translate }}</small>
    </ng-template>
  </ng-select>
  <validation-errors [form]="form" [controlName]="name"></validation-errors>
</div>
