import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';



@Component({
  selector: 'app-selecao-pais',
  templateUrl: './selecao-pais.component.html',
})
export class SelecaoPaisComponent implements OnInit {

  constructor(private _authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) {

  }

  paisId: string;
  paises = [];

  ngOnInit() {
    this._authService.get(`${AuthService.config.GatewayApi}/login/getpaises`)
      .subscribe(paises => {
        this.paises = paises;
      });
  }

  selecionar(form: NgForm) {
    localStorage.setItem('PaisId', this.paisId);
    this.router.navigate(['home']);
  }
}
