<div class="row">
  <div class="col">
    <br />
    <h5 align="center">
      <span>TERMO DE ADESÃO E CONSENTIMENTO DO TITULAR DOS DADOS</span>
    </h5>
    <br />
    <h5 align="center">
      <span>
        Em cumprimento à lei nº 13.709/2018 (LGPD), declaro a minha adesão e consentimento para que a SUKYO MAHIKARI DO BRASIL realize a coleta, armazenamento e compartilhamento de meus DADOS PESSOAIS (nome, profissão, endereço residencial e eletrônico, nºs. de telefone, nº da cédula de identidade, nº do CPF), obedecendo todas as normas de segurança de informação, de inviolabilidade da intimidade, honra e imagem, em arquivos digitais e físicos.
      </span>
    </h5>
  </div>
</div>
<br />
<br />
<div>
  <form #f="ngForm">
    <div class="row">
      <div class="col">
        <div align="center">
          <!-- Salvar -->
          <form-button label={{btnText}}
                       customType="submit"
                       (click)="aceitar(model.hash)"
                       customClass="btn-success"></form-button>
        </div>
      </div>
    </div>
  </form>
</div>
